import BarChart from '../elementos/graficos/barras';
import React from 'react';
import {createRoot} from 'react-dom/client';
import { manejarError } from '../manejadores/manejadores';

export async function cargarInformeXMes(h) {
    const mes = document.getElementById('selectMes').value;
    const anio = document.getElementById('selectAnio').value;
    const e = document.getElementById('selectEstado').value;
    const url = `http://esstock.com.ar:3000/api/filtroMes?e=${encodeURIComponent(e)}&mes=${encodeURIComponent(mes)}&anio=${encodeURIComponent(anio)}`;
    fetch(url)
    .then(response => {
        if (!response.ok) {
            if (response.status === 403 || response.status === 404) {
                manejarError(h);
            }
        }
        return response.json();
    })
    .then(data => {
        let etiquetas = [];
        let datos = [];
        let modelos = localStorage.models.replace(/\s/g, '').split(',');
        data.rows.forEach(row => {
            etiquetas.push(modelos[row.modelo-1]);
            datos.push(parseInt(row.count));
        });
        let retorno = {etiquetas: etiquetas, datos: datos};
        const contenedor = document.querySelector('.graficoXMes');
        createRoot(contenedor).render(<BarChart obj={retorno}/>);
    })
    .catch(error => {
        console.error('Error en la petición:', error);
    });
}

export async function cargarInformeTerminadas30Dias(h) {
    const e = document.getElementById('selectEstado').value;
    const url = `http://esstock.com.ar:3000/api/terminadas30Dias?e=${encodeURIComponent(e)}`;
    fetch(url)
    .then(response => {
        if (!response.ok) {
            if (response.status === 403 || response.status === 404) {
                manejarError(h);
            }
        }
        return response.json();
    })
    .then(data => {
        let etiquetas = [];
        let datos = [];
        let modelos = localStorage.models.replace(/\s/g, '').split(',');
        data.rows.forEach(row => {
            etiquetas.push(modelos[row.modelo-1]);
            datos.push(parseInt(row.count));
        });
        let retorno = {etiquetas: etiquetas, datos: datos};
        createRoot(document.querySelector('.grafico30Dias')).render(<BarChart obj={retorno}/>);
    })
    .catch(error => {
        console.error('Error en la petición:', error);
    });
}

export function cargarInformeTerminadasVsIngresadas(h) {
    const mes = document.getElementById('selectMes').value;
    const anio = document.getElementById('selectAnio').value;
    const url = `http://esstock.com.ar:3000/api/terminadasVsIngresadas?mes=${encodeURIComponent(mes)}&anio=${encodeURIComponent(anio)}`;
    fetch(url)
    .then(response => {
        if (!response.ok) {
            if (response.status === 403 || response.status === 404) {
                manejarError(h);
            }
        }
        return response.json();
    })
    .then(data => {

        const interfaz = document.querySelector('.terminadasVsIngresadasInterface');
        const contenedorBusqueda = document.querySelector('.respuestaBusqueda');
        if (contenedorBusqueda) {interfaz.removeChild(contenedorBusqueda)};
        const container = document.createElement('DIV');
        container.classList.add('respuestaBusqueda');
        interfaz.appendChild(container);
        container.innerHTML = 'RESULTADO:'
        let modelos = localStorage.models.replace(/\s/g, '').split(',');
        data.rows.forEach(row => {
            const element = document.createElement('DIV');
            element.id = `${row.modelo}`;
            element.classList.add('info');
            container.appendChild(element); 
            let content = '';
            for (const key in row) {
                if (key==='modelo') {content += `${modelos[row[key]-1]}, `}
                else {content += `${key}: ${row[key]}, `};
            }
            content = content.slice(0, -2);
            element.innerHTML = content;
        });

    })
    .catch(error => {
        console.error('Error en la petición:', error);
    });
}

export function cargarInformeDiasEstancada(h) {
    const token = sessionStorage.getItem("token");
    if (token) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        };
        fetch('http://esstock.com.ar:3000/api/informes/diasEstancadas', requestOptions)
        .then(response => {
            if (!response.ok) {
                if (response.status === 403 || response.status === 404) {
                    manejarError(h);
                }
            }
            return response.json();
        })
        .then(data => {
            const interfaz = document.querySelector('.diasEstancadaInterface');
            const contenedorBusqueda = document.querySelector('.respuestaBusqueda');
            if (contenedorBusqueda) {interfaz.removeChild(contenedorBusqueda)};
            const container = document.createElement('DIV');
            container.classList.add('respuestaBusqueda');
            interfaz.appendChild(container);
            container.innerHTML = 'RESULTADO:'
            data.forEach(row => {
                const element = document.createElement('DIV');
                element.id = `${row.butaca.nro}`;
                element.classList.add('info');
                element.classList.add(`${row.butaca.estado.nombre}`);
                container.appendChild(element);
                element.innerHTML = `${row.butaca.nro}, ${row.butaca.estado.nombre}, DiasEstancada: ${row.diasEstancada}`;
            });
        })
        .catch(error => {
            console.error('Error en la petición:', error);
        });
    }
}

export function cargarInformeDiasIngreso(h) {
    const token = sessionStorage.getItem("token");
    if (token) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        };
        fetch('http://esstock.com.ar:3000/api/informes/diasDesdeIngreso', requestOptions)
        .then(response => {
            if (!response.ok) {
                if (response.status === 403 || response.status === 404) {
                    manejarError(h);
                }
            }
            return response.json();
        })
        .then(data => {
            const interfaz = document.querySelector('.diasIngresoInterface');
            const contenedorBusqueda = document.querySelector('.respuestaBusqueda');
            if (contenedorBusqueda) {interfaz.removeChild(contenedorBusqueda)};
            const container = document.createElement('DIV');
            container.classList.add('respuestaBusqueda');
            interfaz.appendChild(container);
            container.innerHTML = 'RESULTADO:'
            data.forEach(row => {
                const element = document.createElement('DIV');
                element.id = `${row.butaca}`;
                element.classList.add('info');
                element.classList.add('diasDesdeIng');
                container.appendChild(element); 
                element.innerHTML = `${row.butaca}, DiasDesdeIngreso: ${row.diasDesdeIngreso}`;
            });

        })
        .catch(error => {
            console.error('Error en la petición:', error);
        });
    }
}

export function cargarInformeCortadas(h) {
    const mes = document.getElementById('selectMes').value;
    const anio = document.getElementById('selectAnio').value;
    const url = `http://esstock.com.ar:3000/api/cortadas?mes=${encodeURIComponent(mes)}&anio=${encodeURIComponent(anio)}`;
    fetch(url)
    .then(response => {
        if (!response.ok) {
            if (response.status === 403 || response.status === 404) {
                manejarError(h);
            }
        }
        return response.json();
    })
    .then(data => {
        const interfaz = document.querySelector('.cortadasInterface');
        const contenedorBusqueda = document.querySelector('.respuestaBusqueda');
        if (contenedorBusqueda) {interfaz.removeChild(contenedorBusqueda)};
        const container = document.createElement('DIV');
        container.classList.add('respuestaBusqueda');
        interfaz.appendChild(container);
        container.innerHTML = 'RESULTADO:';
        data.rows.forEach(row => {
            const element = document.createElement('BUTTON');
            element.id = `${row.usuario}`;
            element.classList.add('infoBoton');
            container.appendChild(element); 
            let content = '';
            for (const key in row) {
                if (key==='usuario') {content += `${row[key]}, `}
                else {content += `${key}: ${row[key]}, `};
            }
            content = content.slice(0, -2);
            element.innerHTML = content;
        });

    })
    .catch(error => {
        console.error('Error en la petición:', error);
    });
}

export function detalleFiltro(desde, hasta, estado, mod, h) {
    const token = sessionStorage.getItem("token");
    const url = `http://esstock.com.ar:3000/api/informes/filtroFechas/modelo?fechaD=${encodeURIComponent(desde)}&fechaH=${encodeURIComponent(hasta)}&estado=${encodeURIComponent(estado)}&modelo=${encodeURIComponent(mod)}`;
    if (token) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        };
        fetch(url, requestOptions)
        .then(response => {
            if (!response.ok) {
                if (response.status === 403 || response.status === 404) {
                    manejarError(h);
                }
            }
            return response.json();
        })
        .then(data => {
            const conte = document.querySelector(".data");
            const interfaz = document.querySelector('.respuestaBusqueda');
            if (interfaz) {conte.removeChild(interfaz)}
            /*const contenedorBusqueda = document.querySelector('.grafo');
            if (contenedorBusqueda) {interfaz.removeChild(contenedorBusqueda)};*/
            const container = document.createElement('DIV');
            container.classList.add('respuestaBusqueda');

            container.innerHTML = 'RESULTADO:';
            data.forEach(row => {
                const element = document.createElement('DIV');
                element.id = `${row.id.butaca.nro}`;
                element.classList.add('info');
                element.classList.add(`diasDesdeIng`);
                container.appendChild(element);
                const fecha = row.id.fechaHs;
                let content = `${row.id.butaca.nro}, \t
                ${row.sesion.id.usuario.nombre}, \t
                ${fecha.split('T')[0]}, \t
                ${row.id.butaca.color.nombre}`;
                element.innerHTML = content;
            });
            conte.appendChild(container);
        })
        .catch(error => {
            console.error('Error en la petición:', error);
        });
    }
    
}

export function cargarInformePorFecha(desde, hasta, h) {
    const estado = document.getElementById('selectEstado').value;
    const token = sessionStorage.getItem('token');
    const url = `http://esstock.com.ar:3000/api/informes/filtroFechas?fechaD=${encodeURIComponent(desde)}&fechaH=${encodeURIComponent(hasta)}&estado=${encodeURIComponent(estado)}`;
    if (token) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        };
        fetch(url, requestOptions)
        .then(response => {
            if (!response.ok) {
                if (response.status === 403 || response.status === 404) {
                    manejarError(h);
                }
            }
            return response.json();
        })
        .then(data => {
            let counts = {};
            let dios = document.querySelector(".datepickerInterface")
            let container = document.querySelector('.data');
            let conteinerMod = document.querySelector(".conteoModelos")
            //let hijo = document.querySelector('.grafo');
            //let hijo2 = document.querySelector('.respuestaBusqueda');
            if (container) {dios.removeChild(container)};
            if (conteinerMod) {dios.removeChild(conteinerMod)};
            //if (hijo) {container.removeChild(hijo)};
            //if (hijo2) {container.removeChild(hijo2)};
            
            const datosConte = document.createElement('DIV');
            datosConte.classList.add('data');
            const conteoContainer = document.createElement('DIV');
            conteoContainer.classList.add('conteoModelos');
            dios.appendChild(conteoContainer);
            dios.appendChild(datosConte);

            let etiquetas = [];
            let datos = [];
            data.forEach(row => {

                counts[row.modelo.nombre] = row.cantidad;

                etiquetas.push(row.modelo.nombre);
                datos.push(row.cantidad);
            });

            
            Object.keys(counts).forEach(modelo => {
                const conteoElement = document.createElement('DIV');
                conteoElement.classList.add("conteoElement");
                conteoElement.textContent = `${modelo}   ----->   ${counts[modelo]}`;
                conteoContainer.appendChild(conteoElement);
            });

            let retorno = {etiquetas: etiquetas, datos: datos, desde:desde, hasta:hasta, estado:estado, AS:null};
            createRoot(datosConte).render(<BarChart obj={retorno}/>);

            /*
        const container = document.createElement('DIV');
        container.classList.add('respuestaBusqueda');
        interfaz.appendChild(container);
        container.innerHTML = 'RESULTADO:';
        data.rows.forEach(row => {
            const element = document.createElement('BUTTON');
            element.id = `${row.usuario}`;
            element.classList.add('infoBoton');
            container.appendChild(element); 
            let content = '';
            for (const key in row) {
                if (key==='usuario') {content += `${row[key]}, `}
                else {content += `${key}: ${row[key]}, `};
            }
            content = content.slice(0, -2);
            element.innerHTML = content;
        });v*/



        })
        .catch(error => {
            console.error('Error en la petición:', error);
        });
    }
}

export function botonBuscarEntrAS(desde, hasta, h, tipo){
    const estado = document.getElementById('selectEstado').value;
    if (parseInt(estado)!==5 && parseInt(estado)!==6){
        alert("Estado incorrecto");
    } else {
        const token = sessionStorage.getItem('token');
        const url = `http://esstock.com.ar:3000/api/informes/filtroFechasAS?fechaD=${encodeURIComponent(desde)}&fechaH=${encodeURIComponent(hasta)}&estado=${encodeURIComponent(parseInt(estado))}&tipo=${encodeURIComponent(tipo)}`;
        if (token) {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };
            fetch(url, requestOptions)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 403 || response.status === 404) {
                        manejarError(h);
                    }
                }
                return response.json();
            })
            .then(data => {
                if (data.length==0){alert("no se encontraron resultados")};
                let dios = document.querySelector(".datepickerInterface")
                let container = document.querySelector('.data');
                //let hijo = document.querySelector('.grafo');
                //let hijo2 = document.querySelector('.respuestaBusqueda');
                if (container) {dios.removeChild(container)};
                //if (hijo) {container.removeChild(hijo)};
                //if (hijo2) {container.removeChild(hijo2)};
                
                const datosConte = document.createElement('DIV');
                datosConte.classList.add('data');
                dios.appendChild(datosConte);

                let retorno;
                let etiquetas = [];
                let datos = [];
                data.forEach(row => {
                    etiquetas.push(row.modelo.nombre);
                    datos.push(row.cantidad);
                });
                if (tipo) {
                    retorno = {etiquetas: etiquetas, datos: datos, desde:desde, hasta:hasta, estado:estado, AS:true};
                } else {
                    retorno = {etiquetas: etiquetas, datos: datos, desde:desde, hasta:hasta, estado:estado, AS:false};
                }
                createRoot(datosConte).render(<BarChart obj={retorno}/>);
            })
            .catch(error => {
                console.error('Error en la petición:', error);
            });
        }
    }
}

export function detalleFiltroAS(tipo, desde, hasta, estado, mod, h) {
    const token = sessionStorage.getItem("token");
    const url = `http://esstock.com.ar:3000/api/informes/filtroFechas/modeloAS?fechaD=${encodeURIComponent(desde)}&fechaH=${encodeURIComponent(hasta)}&estado=${encodeURIComponent(estado)}&modelo=${encodeURIComponent(mod)}&tipo=${encodeURIComponent(tipo)}`;
    if (token) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        };
        fetch(url, requestOptions)
        .then(response => {
            if (!response.ok) {
                if (response.status === 403 || response.status === 404) {
                    manejarError(h);
                }
            }
            return response.json();
        })
        .then(data => {
            const conte = document.querySelector(".data");
            const interfaz = document.querySelector('.respuestaBusqueda');
            if (interfaz) {conte.removeChild(interfaz)}
            /*const contenedorBusqueda = document.querySelector('.grafo');
            if (contenedorBusqueda) {interfaz.removeChild(contenedorBusqueda)};*/
            const container = document.createElement('DIV');
            container.classList.add('respuestaBusqueda');

            container.innerHTML = 'RESULTADO:';
            data.forEach(row => {
                const element = document.createElement('DIV');
                element.id = `${row.id.butaca.nro}`;
                element.classList.add('info');
                element.classList.add(`diasDesdeIng`);
                container.appendChild(element);
                const fecha = row.id.fechaHs;
                let content = `${row.id.butaca.nro}, \t
                ${row.sesion.id.usuario.nombre}, \t
                ${fecha.split('T')[0]}`;
                element.innerHTML = content;
            });
            conte.appendChild(container);
        })
        .catch(error => {
            console.error('Error en la petición:', error);
        });
    }
    
}

export function cargarCortadasUsuario(desde, hasta, h) {
    const token = sessionStorage.getItem('token');
    const url = `http://esstock.com.ar:3000/api/informes/cortadas?fechaD=${encodeURIComponent(desde)}&fechaH=${encodeURIComponent(hasta)}`;
    if (token) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        };
        fetch(url, requestOptions)
        .then(response => {
            if (!response.ok) {
                if (response.status === 403 || response.status === 404) {
                    manejarError(h);
                }
            }
            return response.json();
        })
        .then(data => {
            const interfaz = document.querySelector('.datepickerInterface');
            const contenedorBusqueda = document.querySelector('.respuestaBusqueda');
            if (contenedorBusqueda) {interfaz.removeChild(contenedorBusqueda)};
            const container = document.createElement('DIV');
            container.classList.add('respuestaBusqueda');
            interfaz.appendChild(container);
            container.innerHTML = 'RESULTADO:';
            data.forEach(row => { 
                const element = document.createElement('DIV');
                element.classList.add('info');
                element.classList.add('infoCortadas'); 
                let content = '';
                for (const key in row) {
                    content += `${row[key]}, `
                }
                content = content.slice(0, -2);
                element.innerHTML = content;
                container.appendChild(element);
            });

        })
        .catch(error => {
            console.error('Error en la petición:', error);
        });
    }
}

export function compradorHomologadas(h) {
    let nroAnt = document.getElementById('butacaNro').value;
    let nro = nroAnt.toLowerCase();
    if (nro==="") { nro = "butaquerli"};
    const token = sessionStorage.getItem("token");
    if (token) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        };

        fetch(`http://esstock.com.ar:3000/api/homologaciones/comprador?nroButaca=${encodeURIComponent(nro)}`, requestOptions)
        .then(response => {
            if (!response.ok) {
                if (response.status === 403 || response.status === 404) {
                    manejarError(h);
                }
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            if (data.length===0){alert("No se encontraron resultados")};
            const interfaz = document.querySelector('.homologarButacaInterface');
            const contenedorBusqueda = document.querySelector('.respuestaBusqueda');
            if (contenedorBusqueda) {interfaz.removeChild(contenedorBusqueda)};
            const container = document.createElement('DIV');
            container.classList.add('respuestaBusqueda');
            interfaz.appendChild(container);
            container.innerHTML = 'RESULTADO:';
            data.forEach(row => {
                const element = document.createElement('DIV');
                element.id = `${row.butaca}`;
                element.classList.add('but');
                container.appendChild(element); // Agrega el elemento a la página
                let content = row.nombreModelo + ", " + row.butaca + ", " + row.etiquetaBlanca + ", " + row.etiquetaGris ;
                let modelo = row.nombreModelo.replace(/\s/g, '');
                element.classList.add(modelo); 
                element.innerHTML = content;

                element.addEventListener("click", ()=>{
                    interfazComprador(element.id, h);
                });
            });
        })
        .catch(error => {
            console.error('Error en la petición:', error);
        });
    };
}

function interfazComprador(nro, h) {
    //sacar el anterior select estado
    const seEs = document.querySelector('.todo');
    const cont = document.getElementById('root');
    //cont.removeChild(seEs);

    //contenedor dentro de contenedor nuevo
    const contenedor = document.createElement('DIV');
    contenedor.classList.add("homButInterface");

    const contenedor2 = document.createElement('DIV');
    contenedor2.classList.add("divFor");
    contenedor2.innerHTML = `REGISTRAR COMPRADOR DE LA BUTACA NRO:<br><h3 color='#000'>${nro}</h3>`;

    const input3 = document.createElement('input');
    input3.setAttribute('type', 'text');
    input3.setAttribute('id', `comprador`);
    input3.setAttribute('placeholder', 'Comprador');
    contenedor2.appendChild(input3);

    // Crear los botones
    const btnHomologarButaca = document.createElement('button');
    btnHomologarButaca.setAttribute('id', 'btnHomologarButaca');
    btnHomologarButaca.textContent = 'Registrar';
    contenedor2.appendChild(btnHomologarButaca);
    contenedor.appendChild(contenedor2);

    const btnVolverModBut = document.createElement('button');
    btnVolverModBut.setAttribute('id', 'volverHomBut');
    btnVolverModBut.textContent = 'Cancelar';
    contenedor.appendChild(btnVolverModBut);

    document.body.appendChild(contenedor);
    
    // prog botones
    btnHomologarButaca.addEventListener("click", ()=>{
        const comprador = document.getElementById("comprador").value;
        agregarComprador(comprador, nro, h);
    });

    btnVolverModBut.addEventListener("click", ()=>{
        document.body.removeChild(contenedor);
        //cont.appendChild(seEs);
        compradorHomologadas();
    });
}

async function agregarComprador(comp, nro) {
    const inter = document.querySelector(".homButInterface");
    if (comp==="") { 
        alert("Campo vacio");
        return null;
    };
    try {
        const token = sessionStorage.getItem("token");
        if (token) {
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            const response = await fetch(`http://esstock.com.ar:3000/api/homologaciones/comprador?comprador=${encodeURIComponent(comp)}&nroButaca=${encodeURIComponent(nro)}`, requestOptions);
            const datos = await response.json();
            if (datos !== undefined) {
                const element = document.createElement('DIV');
                element.id = 'mensaje-modificacion';
                element.innerHTML = `${nro} REGISTRADO CORRECTAMENTE`;
                if (inter) {inter.appendChild(element)}
                const divFor = document.querySelector(".divFor");
                inter.removeChild(divFor);
            }
        }

    } catch (error) {
        console.log(error);
        //alert("No saltear estados, ni volver a cortada. Excepcion: borrarla");
        const element = document.createElement('DIV');
        element.id = 'mensaje-error-mod';
        element.innerHTML = `Error al registrar comoprador ${nro}`;
        if (inter) {inter.appendChild(element)};
    }
}
