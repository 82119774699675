function manejarCorrectoLogin(h) {
    /*MANEJAR TEMA DE AUTORIZACION VALIDACION Y TOKENS*/
    h('/menu');
    const btnLogout = document.getElementById('logout');
}

function manejarIncorrectoLogin() {
    const mensajeErrorElement = document.getElementById("mensaje-error");
    mensajeErrorElement.style.display = "block";
}

export async function botonLogin(h) {
    const data = {
        nombre: document.getElementById("user").value.toLowerCase(),
        pass: document.getElementById("pass").value.toLowerCase()
      };

    fetch("http://esstock.com.ar:3000/auth/login", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Error: ' + response.status);
        }
    })
    .then(data => {
        sessionStorage.setItem("token", data.token);
        sessionStorage.setItem("pkSesionHs", data.pk.fechaHsDesde);
        manejarCorrectoLogin(h);
    })
    .catch(error => {
        console.error('Error:', error);
        manejarIncorrectoLogin();
    });


    /*try {
        const response = await fetch(`http://esstock.com.ar:3000/api/login?user=${encodeURIComponent(user)}&pass=${encodeURIComponent(pass)}`);
        if (!response.ok) {
            throw new Error('Error en la solicitud');
        }
        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Error en la petición:', error);
        throw error; 
    }*/
}

export function funcionLogout(history) {
    const btnLogout = document.getElementById('logout');
    if (btnLogout) {btnLogout.style.display = 'none'};
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('pkSesionHs');
    if (history) {history('/')};
    // eslint-disable-next-line no-restricted-globals
    location.reload()
}

export function botonLogout(h) {
    const sesHs = sessionStorage.getItem("pkSesionHs");
    try {
        fetch(`http://esstock.com.ar:3000/api/sesion?fechaHsSesion=${encodeURIComponent(sesHs)}`, {
            method: 'PUT',
            headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${sessionStorage.token}`
            },
        })
        .then(response => {
            if (response.status===200){
                funcionLogout(h);
            }
        })
        .catch(error => {
            console.error('Error en la petición:', error);
        });
    } catch(error) {
        console.error('Error en la petición:', error);
    };
}

export function verificarToken(h) {
    const token = sessionStorage.getItem("token");
    if (token) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        };
        fetch('http://esstock.com.ar:3000/api/informes', requestOptions)
        .then(response => {
            if (response.status==403){h('/menu')}
        })
        .catch(error => {
            console.error('Error en la petición:', error);
            h('/menu');
        });
    } else {h('/menu')}
}
