import { BotonLogout } from '../../elementos/btnLogout';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { cargarEstados } from '../../services/manejo-butacas';
import { cargarInformeXMes } from '../../services/manejo-informes';

export const FiltroMes = ()=> {
    const history = useNavigate();

    //let c = 0;

    useEffect(() => {
    //    c += 1;
    //    if (c===2) {
            //if (localStorage.token===undefined){history('/')};
            optionsAnio();
            optionsMes();
            cargarEstados('option');
    //    };
    }, []);

    function optionsAnio() {
        const selectYear = document.getElementById("selectAnio");
        for (let year = 2023; year <= 2040; year++) {
            const option = document.createElement("option");
            option.text = year;
            option.value = year;
            selectYear.appendChild(option);
        }
    }
    
    function optionsMes() {
        const selectMonth = document.getElementById("selectMes");
        const meses = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];
    
        for (let i = 0; i < meses.length; i++) {
            const option = document.createElement("option");
            option.text = meses[i];
            option.value = i+1;
            selectMonth.appendChild(option);
        }
    } 
    

    return(
        <div className="terminadas30DiasInterface">
            <BotonLogout h={history} />
            <button id="volverMenu" onClick={()=> history('/informes')}>Volver</button>
            <select name="selectEstado" id="selectEstado"></select>
            <select id='selectAnio'></select>
            <select id='selectMes'></select>
            <button id='traerInfoInformeXMes' onClick={()=>cargarInformeXMes(history)}>Traer Info</button>
            <div className='graficoXMes'/>
        </div>
    )
}