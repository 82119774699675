import { BotonLogout } from '../../elementos/btnLogout';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { cargarEstados } from '../../services/manejo-butacas';
import { cargarInformePorFecha, botonBuscarEntrAS } from '../../services/manejo-informes';

export const DateRangePicker = ()=> {
    const history = useNavigate();

    //let c = 0;

    useEffect(() => {
    //    c += 1;
    //    if (c===2) {
            if (sessionStorage.token===undefined){history('/')};
            cargarEstados('option');
    //    };
    }, []);

    const formatDate = (date) => {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}T00:00:00`;
    };

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
  
    const handleStartDateChange = (date) => {
      setStartDate(date);
    };
  
    const handleEndDateChange = (date) => {
      setEndDate(date);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();

      cargarInformePorFecha(formatDate(startDate), formatDate(endDate), history);
    };
  
    return (
      <div className='datepickerInterface'>
        <BotonLogout h={history} />
        <form onSubmit={handleSubmit}>
          <div>
            <DatePicker
              className='datepickerDesde'
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Fecha Desde INCLUSIVE"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div>
            <DatePicker
              className='datepickerDesde'
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="Fecha Hasta"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <select name="selectEstado" id="selectEstado"></select>
          <div>
            <button id="enviarPicker">Enviar</button>
          </div>
        </form>
        <button id="buscarEntrA" onClick={()=>{botonBuscarEntrAS(formatDate(startDate), formatDate(endDate), history, false)}}>A</button>
        <button id="buscarEntrS" onClick={()=>{botonBuscarEntrAS(formatDate(startDate), formatDate(endDate), history, true)}}>S</button>
        <button id="volverMenu" onClick={()=> history('/informes')}>Volver</button>
        <div className='data'/>
      </div>
    );
}
  
