import { BotonLogout } from '../../elementos/btnLogout';
import { useNavigate } from 'react-router-dom';
import { botonCrearButaca, cargarModelos} from '../../services/manejo-butacas';
import React, {useEffect} from 'react';

export const RegistrarButaca = ()=> {
    const history = useNavigate();
    
    //let c = 0;

    useEffect(() => {
    //    c += 1;
    //    if (c===2) {
            cargarModelos('option');
    //    };
    }, []);

    return(
        <div className="registrarButacaInterface">
            <BotonLogout h={history} />
            <h2>Registrar butaca</h2>
            <input type="text" placeholder="Nro de Butaca" name="butacaNro" id="butacaNro"></input>
            <select name="selectModelo" id="selectModelo">
                <option value="0" defaultChecked>-</option>
            </select>
            <select name="selectColor" id="selectColor">
                <option value="0" defaultChecked>-</option>
                <option value="1">Negra</option>
                <option value="2">Roja</option>
                <option value="3">Blanca</option>
            </select>
            <button id="crearButaca" onClick={()=>{botonCrearButaca(history)}}>Crear butaca</button>
            <button id="volverMenu" onClick={()=> history('/fabrica')}>Volver</button>
            <div id="mensaje-creacion-butaca"></div>

        </div>
    )
}
