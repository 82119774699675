import React, { useEffect } from 'react';
import {Chart, Title} from 'chart.js';
import { LinearScale, CategoryScale, BarController, BarElement } from 'chart.js';
import { detalleFiltro, detalleFiltroAS } from '../../services/manejo-informes';

Chart.register(LinearScale, CategoryScale, BarController, BarElement, Title);

const BarChart = (obj) => {
  useEffect(() => {
    // Obtén una referencia al elemento canvas
    const canvas = document.getElementById('myBarChart');

    // Comprueba si ya hay un gráfico asociado al canvas y, de ser así, destrúyelo
    const existingChart = Chart.getChart(canvas);
    if (existingChart) {
      existingChart.destroy();
    }

    // Datos del gráfico
    const data = {
      labels: obj.obj.etiquetas,
      datasets: [{
        label: 'Barras',
        data: obj.obj.datos, // Valores numéricos para cada barra
        backgroundColor: ['IndianRed', 'Pink', 'Coral', 'DarkKhaki', 'MediumPurple', 'PaleGreen'], // Colores para cada barra
      }],
    };

    // Opciones del gráfico (opcional)
    const options = {
      onClick: (event, elements) => {
        if (elements.length>0) {
          const clickedBar = elements[0].index;
          if (obj.obj.AS) {
            detalleFiltroAS(true, obj.obj.desde, obj.obj.hasta, obj.obj.estado, obj.obj.etiquetas[clickedBar]);
          } else if (obj.obj.AS===null){
            detalleFiltro(obj.obj.desde, obj.obj.hasta, obj.obj.estado, obj.obj.etiquetas[clickedBar]);
          } else if (obj.obj.AS===false){
            detalleFiltroAS(false, obj.obj.desde, obj.obj.hasta, obj.obj.estado, obj.obj.etiquetas[clickedBar]);
          }
        }
      },
      onHover: (event, chartElement) => {
        event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true, // Iniciar el eje Y en cero
          ticks: {
            stepSize: 1,
          },  
        },
      },
      plugins: {
        title: {
          display: true,
          text: 'Grafico de barras',
        },
        datalabels: {
          anchor: 'end',
          align: 'end',
          formatter: (value) => value, // Mostrar el valor exacto
        },
      },
    };

    // Obtener el contexto del canvas
    const ctx = document.getElementById('myBarChart').getContext('2d');

    // Crear el gráfico de barras
    new Chart(ctx, {
      type: 'bar',
      data: data,
      options: options,
    });
  }, [obj]);

  return (
    <div className='grafo'>
      <canvas id="myBarChart" width="400" height="350"></canvas>
    </div>
  );
};

export default BarChart;


/*import {Chart} from 'chart.js';

export default function barChart(etiqueta, datos) {
  // Datos del gráfico
  const data = {
    labels: etiqueta,
    datasets: [{
      label: 'Mi gráfico de barras',
      data: datos, // Valores numéricos para cada barra
      backgroundColor: ['red', 'blue', 'green', 'orange', 'purple'], // Colores para cada barra
    }],
  };

  // Opciones del gráfico (opcional)
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true, // Iniciar el eje Y en cero
      },
    },
  };

  // Obtener el contexto del canvas
  const contenedor = document.querySelector('.grafico30Dias');
  const element = document.createElement('DIV');
  element.id = 'myBarChart';
  element.width = '400';
  element.height = '400';
  contenedor.appendChild(element);
  const ctx = document.getElementById('myBarChart').getContext('2d');

  // Crear el gráfico de barras
  new Chart(ctx, {
    type: 'bar',
    data: data,
    options: options,
  });
};*/
