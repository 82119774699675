import { BotonLogout } from '../../elementos/btnLogout';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import {cargarInformeDiasIngreso} from '../../services/manejo-informes';

export const DiasIngreso = ()=> {
    const history = useNavigate();

    //let c = 0;

    useEffect(() => {
    //    c += 1;
    //    if (c===2) {
            if (sessionStorage.token===undefined){history('/')};
            cargarInformeDiasIngreso(history);
    //    };
    }, []);

    return(
        <div className="diasIngresoInterface">
            <BotonLogout h={history} />
            <button id="volverMenu" onClick={()=> history('/informes')}>Volver</button>
        </div>
    )
}
