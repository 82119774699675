import { BotonLogout } from '../../elementos/btnLogout';
import { useNavigate } from 'react-router-dom';
import React, {useEffect} from 'react';
import { compradorHomologadas } from '../../services/manejo-informes';

export const CompradorHomologadas = ()=> {
    const history = useNavigate();

    function volver() {
        history('/informes');
    }

    //let c = 0;

    useEffect(() => {
        //c += 1;
        //if (c===2) {
            compradorHomologadas(history);
        //};
    }, []);

    return(
        <div className="homologarButacaInterface">
            <BotonLogout h={history} />
            <h2>Registrar comprador</h2>
            <input type="text" placeholder="Nro de Butaca" name="butacaNro" id="butacaNro"></input>
            <button id="buscarButaca" onClick={()=>{compradorHomologadas(history)}}>Buscar Homologacion</button>
            <button id="volverMenu" onClick={()=> volver()}>Volver</button>
        </div>
    )
}