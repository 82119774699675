import { useNavigate } from 'react-router-dom';
import { BotonLogout } from '../elementos/btnLogout';
import React, { useEffect } from 'react';
import { cargarEstados, cargarModelos } from '../services/manejo-butacas';

export const Menu = ()=> {
    const history = useNavigate();

    //let c = 0;

    useEffect(() => {
        //c += 1;
        //if (c===2) {
            if (sessionStorage.token===undefined){history('/')};
            cargarEstados();
            cargarModelos();
        //};
    }, []);

    return(
        <>
            <div className="menuInterface">
                <BotonLogout h={history} />
                <h2>Inicio</h2>
                <button id="fabrica" onClick={()=> history('/fabrica')}>Fabrica</button>
                <button id="informes" onClick={()=> history('/informes')}>Informes</button>
            </div>
        </>
    )
}
