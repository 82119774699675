import { BotonLogout } from '../elementos/btnLogout';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { recuentoStockListas } from '../services/manejo-butacas';

export const Fabrica = ()=> {
    const history = useNavigate();

    //let c = 0;

    useEffect(() => {
        //c += 1;
        //if (c===2) {
            if (sessionStorage.token===undefined){history('/')};
            recuentoStockListas(history);
        //};
    }, []);

    return(
        <div className="fabricaInterface">
            <BotonLogout h={history} />
            <h2>Fabrica</h2>
            <button id="registrarButaca" onClick={()=> history('/fabrica/registrarButaca')}>Registrar Butaca</button>
            <button id="buscarButaca" onClick={()=> history('/fabrica/buscarButaca')}>Buscar Butaca</button>
            <button id="homologarButacas" onClick={()=> history('/fabrica/homologarButacas')}>Homologar Butaca</button>
            <button id="volverMenu" onClick={()=> history('/menu')}>Volver</button>
        </div>
    )
}
