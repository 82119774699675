import { botonLogin } from '../services/manejo-login';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { botonLogout } from '../services/manejo-login';

export const Inicio = ()=> {
    const history = useNavigate();

    useEffect(() => {
        if (sessionStorage.token) {botonLogout(history)};
        localStorage.clear();
    }, []);

    return(
        <>
            <div className='formLogin'>
                <h2>Iniciar sesión</h2>
                <label htmlFor="username">Nombre de usuario:</label>
                <input type="text" id="user" placeholder="Ingrese su nombre de usuario"></input>

                <label htmlFor="password">Contraseña:</label>
                <input type="password" id="pass" placeholder="Ingrese su contraseña"></input>

                <div className="button-container">
                    <button id="login" onClick={()=>botonLogin(history)}>Iniciar Sesion</button>
                </div>
                <div id="mensaje-error">El usuario o contrasenia son incorrectos</div>
            </div>
        </>
    )
}