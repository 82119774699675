import { BotonLogout } from '../../elementos/btnLogout';
import { useNavigate } from 'react-router-dom';
import { volverFab, botonHomologarButacas} from '../../services/manejo-butacas';
import React, {useEffect} from 'react';

export const HomologarButaca = ()=> {
    const history = useNavigate();
    
    //let c = 0;

    function volver() {
        history('/fabrica');
    }

    useEffect(() => {
    //    c += 1;
    //    if (c===2) {
            botonHomologarButacas(history);
    //    };
    }, []);

    return(
        <div className="homologarButacaInterface">
            <BotonLogout h={history} />
            <h2>Homologar butaca</h2>
            <input type="text" placeholder="Nro de Butaca" name="butacaNro" id="butacaNro"></input>
            <button id="buscarButaca" onClick={()=>{botonHomologarButacas(history)}}>Buscar Butaca</button>
            <button id="volverMenu" onClick={()=> volver()}>Volver</button>
        </div>
    )
}
