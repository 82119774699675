import { BotonLogout } from '../elementos/btnLogout';
import { useNavigate } from 'react-router-dom';
import React, {useEffect} from 'react';
import { verificarToken } from '../services/manejo-login';
import { excelHomologadas } from '../services/manejo-butacas';
import { compradorHomologadas } from '../services/manejo-informes';

export const Informes = ()=> {
    const history = useNavigate();

    //let c = 0;

    useEffect(() => {
        //c += 1;
        //if (c===2) {
            verificarToken(history);
        //};
    }, []);

    return(
        <div className="informesInterface">
            <BotonLogout h={history} />
            <h2>Informes</h2>
            {/*<button id="btnCortadas" onClick={()=> history('/informes/cortadas')}>Cortadas</button>*/}
            <button id="btnDiasEstancada" onClick={()=> history('/informes/diasEstancada')}>Dias estancadas</button>
            <button id="btnDiasIngreso" onClick={()=> history('/informes/diasIngreso')}>Dias desde ingreso</button>
            <button id="datePickerBtn" onClick={()=> history('/informes/datePicker')}>Elegir fechas</button>
            {/*<button id="fechaUsuarioBtn" onClick={()=> history('/informes/fechaUsuarioButaca')}>Cortadas por usuario</button>*/}
            <button id="btnComprador" onClick={()=> history('/informes/comprador')}>Comprador homologadas</button>
            <button id="btnExcel" onClick={()=> excelHomologadas()}>Excel homologadas</button>
            <button id="volverMenu" onClick={()=> history('/menu')}>Volver</button>
        </div>
    )
}
