import { BotonLogout } from '../../elementos/btnLogout';
import { useNavigate } from 'react-router-dom';
import { volverFab, modifButaca, botonBuscarButacas, cargarEstados, cargarModelos, botonBuscarAS } from '../../services/manejo-butacas';
import React, {useEffect} from 'react';

export let seleccionadosVar;

export const BuscarButaca = ()=> {
    const history = useNavigate();
    
    //let c = 0;

    function volver() {
        volverFab();
        history('/fabrica');
    }

    useEffect(() => {
    //    c += 1;
    //    if (c===2) {
            cargarModelos('option');
            cargarEstados('option');
            seleccionadosVar = [];
    //    };
    }, []);

    return(
        <div className="buscarButacaInterface">
            <BotonLogout h={history} />
            <h2>Buscar butaca</h2>
            <input type="text" placeholder="Nro de Butaca" name="butacaNro" id="butacaNro"></input>
            <select name="selectEstado" id="selectEstado">
                <option value="0" defaultValue>Todos</option>
            </select>
            <select name="selectModelo" id="selectModelo">
                <option value="0" defaultValue>Todos</option>
            </select>
            <select name="selectColor" id="selectColor">
                <option value="0" defaultValue>Todos</option>
                <option value="1">Negra</option>
                <option value="2">Roja</option>
                <option value="3">Blanca</option>
            </select>
            <button id="buscarA" onClick={()=>{botonBuscarAS(history, "A")}}>Listas A</button>
            <button id="buscarS" onClick={()=>{botonBuscarAS(history, "S")}}>Listas S</button>
            <button id="buscarButaca" onClick={()=>{botonBuscarButacas(history)}}>Buscar Butaca</button>
            <button id="volverMenu" onClick={()=> volver()}>Volver</button>
            <button id="seleccionados" onClick={()=> modifButaca(history)}>Seleccionados</button>
        </div>
    )
}
